<template>
  <div>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Help Link -->
        <div
          v-if="props.column.field === 'helpLink'"
          cols="12"
          md="2"
          class="vgt-center-align "
        >
          <feather-icon
            icon="LinkIcon"
            size="20"
          />
        </div>
        <!-- Column: Actions -->
        <div
          v-else-if="props.column.label === 'Actions'"
          cols="12"
          md="2"
          class="vgt-center-align "
        >
          <b-button
            variant="outline-none"
            @click="modalShowEdit = !modalShowEdit"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </b-button>

          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>

    <b-modal
      id="modal-edit-tooltip"
      v-model="modalShowEdit"
      title="Edit Tooltip"
      centered
      size="lg"
      ok-title="Submit"
      cancel-variant="outline-secondary"
    >
      <edit-tooltip :item="element" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal, 
  VBToggle,
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';

import EditTooltip from "./EditTooltip.vue"; 

import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";

import flatPickr from "flatpickr";

import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

export default {
  components: {
    VueGoodTable,
    BButton,
    BModal,
    TablePagination,
    EditTooltip
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      modalShowEdit: false,
      isLoading: false,
      perPage: 10,
      page: 1,
      element : { },
      dir: false,

      columns: [
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Title',
          },
        },
        {
          label: 'Slug',
          field: 'slug',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Slug',
          },
        },
        {
          label: 'Text',
          field: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Text',
          },
          width: '30em',
        },
        {
          label: 'Help Link',
          field: 'helpLink',
          tdClass: 'text-center',
          width: '8em',
        },

        {
          label: 'Last Modified',
          field: 'lastModified',
            filterOptions: {
                enabled: true,
                placeholder: "Filter Date",
                filterFn: this.dateFilter
            },
            tdClass: "search-date-selector",
            width: "10em",
            type: 'date',
            dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
            dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },

        {
          label: 'Actions',
          field: 'id',
          width: '8em',
          tdClass: 'text-center',
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: '',
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  mounted() {
    const inputs = document.getElementsByClassName('search-date-selector');
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
      });
    });
  },
  created() {
    this.$http.get('/good-table/tooltips')
      .then(res => { this.rows = res.data; });
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
            'title',
            'slug',
            'text',
            'lastModified']) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      // TODO: Pending create Service for Tooltips and connect to real DB
      /*
        try {
            this.isLoading = true;
            const response = await tooltipsService.getList({
                page: this.page,
                perPage: this.perPage,
                columnFilters: this.columnFilters,
                sort: this.sort,
            });
            this.total = response.data.total;
            this.rows = response.data.items;
        } catch (e) {
            this.$log.error(e);
        }*/
      this.isLoading = true;
    },
  },
};
</script>

<style>
  .search-date-selector{
    text-align: center !important;
  }
</style>

<style lang="scss" scoped>
  .badge {
      font-weight: 400 !important;

  }
  table.vgt-table td {
      vertical-align: middle;
  }

</style>

<style lang="scss" >
@import '/src/assets/scss/vue-good-table.scss';
</style>
