<template>
  <b-card>
    <!-- Table Top -->
    <b-row class="list-top-buttons">
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="ml-2"
            @click="modalShow = !modalShow"
          >
            <span class="text-nowrap">Add Tooltip</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <tooltips-list />

    <b-modal
      id="modal-add-tooltip"
      v-model="modalShow"
      title="Add New Tooltip"
      centered
      size="lg"
      ok-title="Submit"
      cancel-variant="outline-secondary"
    >
      <add-tooltip /> 
    </b-modal>

    <b-modal
      id="modal-edit-tooltip"
      v-model="modalShowEdit"
      title="Edit Tooltip"
      centered
      size="lg"
      ok-title="Submit"
      cancel-variant="outline-secondary"
    >
      <edit-tooltip :item="element" /> 
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow, BCol, BCard,
} from 'bootstrap-vue';

import AddTooltip from './AddTooltip.vue';
import EditTooltip from './EditTooltip.vue';
import TooltipsList from './TooltipsList.vue';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    AddTooltip,
    EditTooltip,
    TooltipsList
  },
  data() {
    return {
      modalShow: false,
      modalShowEdit: false,
      element: {},
      rows: [],
      searchTerm: '',
    };
  },
};
</script>

<style>
.badge {
    font-weight: 400 !important;
}
</style>
