<template>
  <div>
    <AomLanguages>
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              :id="'title_'+lan.id"
              placeholder="Title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Slug"
            label-for="slug"
          >
            <b-form-input
              :id="'slug_'+lan.id"
              placeholder="Slug"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="12">
          <div>
            <label for="textarea-default">Text</label>
            <b-form-textarea
              :id="'text_'+lan.id"
              placeholder="Text"
              rows="3"
            />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col md="12">
          <b-form-group
            label="Help Link"
            label-for="helplink"
          >
            <b-form-input
              :id="'helplink_'+lan.id"
              placeholder="Help Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </AomLanguages>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  VBModal, BFormTextarea,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AomLanguages from "@aom-core/AomLanguages";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea, 
    AomLanguages
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      /* eslint-disable global-require */
      dir: "ltr",
      title: '',
      slug: '',
      text: '',
      helplink: '',
      element : { },
      
      lan: {
        id: 1,
        name: "English",
        abbreviation: "eng",
      },
    /* eslint-disable global-require */
    };
  },
  methods: {
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${ files.length } files selected`;
    },
  },
};
</script> 
